/*eslint-disable */
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"
const DiscussionThread = {
  /**
   * DiscussionThreadList
   */
 async DiscussionThreadList (context, qbkId) {
  Utility.showLoader = true;
  Utility.loadingMsg = "Please wait....";
  try{
  let post_data = new FormData();
  
  post_data.append('qbk_id', qbkId);

  return await request.curl(context, "discussion_thread_list", post_data)
    .then(async response => {
      return response;
    });
  }
  catch(err){
    console.error('Exception occurred at postDelete() and Exception:',err.message)
  }
  finally {
    Utility.showLoader = false;
  }
 }
}

export {
  DiscussionThread
}
